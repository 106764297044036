export const data={
  "name": "山西大土河国际贸易有限公司",
  "nameEn": "Shanxi Datuhe International Trade Co., Ltd.",
  "aaa_id": "156021447566",
  "children": [
      {
          "aaa_id": "156018621449",
          "entname": "山西大土河焦化有限责任公司",
          "entnameen": "Datuhe (Shanxi) Coking&Chemicals Co.,Ltd.",
          "invtypecode": "2",
          "invtype": "中国企业",
          "invtypeen": "Domestic Companies",
          "creditcode": "911400007410965483",
          "subconam": "50000000.00",
          "currency": "人民币元",
          "currencyen": "CNY",
          "congrocurcode": "142",
          "fundedratio": 100,
          "conform": "",
          "condate": null,
          "commentsCn": "",
          "commentsEn": "",
          "sourceCn": "",
          "sourceEn": "",
          "directParent": "",
          "entstatuscode": "1",
          "entstatus": "在营",
          "entstatusen": "Active",
          "regcap": "5.0E+8",
          "regcapcur": "人民币",
          "regcapcuren": "CNY",
          "regcapcurcode": "142",
          "enttype": "有限责任公司(自然人投资或控股)",
          "enttypecode": "1130",
          "enttypeen": "Limited Liability Company (Invested or Controlled by Natural Person)",
          "regorg": "山西省市场监督管理局",
          "regorgen": "Shanxi Provincial Administration for Market Regulation",
          "entclasscode": "1001",
          "entclass": "有限责任公司",
          "entclassen": "Limited Liability Company",
          "regorgcode": "141102",
          "regorgprovince": "山西省",
          "regorgprovinceen": "Shanxi",
          "regorgcity": "吕梁市",
          "regorgcityen": "Luliang",
          "regorgdistrict": "离石区",
          "regorgdistricten": "Lishi District",
          "dom": "大土河村",
          "domen": " Datuhe Village",
          "esdate": "2002-09-20",
          "revdate": null,
          "candate": null,
          "frname": "高建斌",
          "frnameen": "Gao Jianbin",
          "pinvamount": "1",
          "enumerationcn": null,
          "enumerationen": null,
          "fatherAaa_id": "156021447566",
          "name": "山西大土河焦化有限责任公司",
          "nameEn": "Datuhe (Shanxi) Coking&Chemicals Co.,Ltd.",
          "value": 100,
          "itemStyle": {
              "color": "#F72C5B"
          },
          "lineStyle": {
              "color": "#F72C5B"
          },
          "isMax": "156021447566",
          "children": [
              {
                  "aaa_id": "",
                  "entname": "贾廷亮",
                  "entnameen": "Jia Tingliang",
                  "invtypecode": "1",
                  "invtype": "国内自然人",
                  "invtypeen": "Domestic Individuals",
                  "creditcode": null,
                  "subconam": "412500000.00",
                  "currency": "人民币元",
                  "currencyen": "CNY",
                  "congrocurcode": "142",
                  "fundedratio": 82.5,
                  "conform": "",
                  "condate": null,
                  "commentsCn": "",
                  "commentsEn": "",
                  "sourceCn": "",
                  "sourceEn": "",
                  "directParent": "",
                  "entstatuscode": null,
                  "entstatus": null,
                  "entstatusen": null,
                  "regcap": null,
                  "regcapcur": null,
                  "regcapcuren": null,
                  "regcapcurcode": null,
                  "enttype": null,
                  "enttypecode": null,
                  "enttypeen": null,
                  "regorg": null,
                  "regorgen": null,
                  "entclasscode": null,
                  "entclass": null,
                  "entclassen": null,
                  "regorgcode": null,
                  "regorgprovince": null,
                  "regorgprovinceen": null,
                  "regorgcity": null,
                  "regorgcityen": null,
                  "regorgdistrict": null,
                  "regorgdistricten": null,
                  "dom": null,
                  "domen": null,
                  "esdate": null,
                  "revdate": null,
                  "candate": null,
                  "frname": null,
                  "frnameen": null,
                  "pinvamount": "2",
                  "enumerationcn": null,
                  "enumerationen": null,
                  "fatherAaa_id": "156018621449",
                  "name": "贾廷亮",
                  "nameEn": "Jia Tingliang",
                  "value": 82.5,
                  "itemStyle": {
                      "color": "#F72C5B"
                  },
                  "lineStyle": {
                      "color": "#F72C5B"
                  },
                  "isMax": true,
                  "children": []
              },
              {
                  "aaa_id": "",
                  "entname": "王锁兰",
                  "entnameen": "Wang Suolan",
                  "invtypecode": "1",
                  "invtype": "国内自然人",
                  "invtypeen": "Domestic Individuals",
                  "creditcode": null,
                  "subconam": "87500000.00",
                  "currency": "人民币元",
                  "currencyen": "CNY",
                  "congrocurcode": "142",
                  "fundedratio": 17.5,
                  "conform": "",
                  "condate": null,
                  "commentsCn": "",
                  "commentsEn": "",
                  "sourceCn": "",
                  "sourceEn": "",
                  "directParent": "",
                  "entstatuscode": null,
                  "entstatus": null,
                  "entstatusen": null,
                  "regcap": null,
                  "regcapcur": null,
                  "regcapcuren": null,
                  "regcapcurcode": null,
                  "enttype": null,
                  "enttypecode": null,
                  "enttypeen": null,
                  "regorg": null,
                  "regorgen": null,
                  "entclasscode": null,
                  "entclass": null,
                  "entclassen": null,
                  "regorgcode": null,
                  "regorgprovince": null,
                  "regorgprovinceen": null,
                  "regorgcity": null,
                  "regorgcityen": null,
                  "regorgdistrict": null,
                  "regorgdistricten": null,
                  "dom": null,
                  "domen": null,
                  "esdate": null,
                  "revdate": null,
                  "candate": null,
                  "frname": null,
                  "frnameen": null,
                  "pinvamount": "2",
                  "enumerationcn": null,
                  "enumerationen": null,
                  "fatherAaa_id": "156018621449",
                  "name": "王锁兰",
                  "nameEn": "Wang Suolan",
                  "value": 17.5,
                  "itemStyle": {
                      "color": "#ccc"
                  },
                  "lineStyle": {
                      "color": "#ccc"
                  },
                  "isMax": false,
                  "children": []
              }
          ]
      }
  ]
}